import React, { useContext, useState, useEffect } from 'react';
import Fade from 'react-reveal/Fade';
import { Container, Row, Col } from 'react-bootstrap';
import Title from '../Title/Title';
import AboutImg from '../Image/AboutImg';
import PortfolioContext from '../../context/context';

const About = () => {
  const { about } = useContext(PortfolioContext);
  const { img, paragraphOne, paragraphTwo, paragraphThree, resume } = about;

  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
  }, []);

  return (
    <section id="about">
      <Container>
        <Title title="About Me" />
        <Row className="about-wrapper">
          <Col md={6} sm={12}>
            <Fade bottom duration={1000} delay={600} distance="30px">
              <div className="about-wrapper__image">
                <AboutImg alt="profile picture" filename={img} />
              </div>
            </Fade>
          </Col>
          <Col md={6} sm={12}>
            <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={1000} distance="30px">
              <div className="about-wrapper__info">
                <p className="about-wrapper__info-text">
                  {paragraphOne ||
                    "I'm a web developer who's passionate about all things JavaScript. I've created over 20 projects in React and Vanilla JS."}
                </p>
                <p className="about-wrapper__info-text">
                  {paragraphTwo ||
                    "I'm a developer, because I'm inspired by the idea of constantly acquiring knowledge and in this space, there's always something that you don't know. Put simply, I love to learn and this project is a great example of that! I picked up a bit of Gatsby so I could create this cool looking personal website! ''Build things. Lots of things. Build 1,000 things. Keep it up and don't stop. Seriously.'' That quote by Wes Bos is my secret to picking up new skills."}
                </p>
                <p className="about-wrapper__info-text">
                  {paragraphThree ||
                    "I'm currently looking for an opportunity to work with a JavaScript framework and I'd ultimately like to end up working with React as that's what I've created the majority of my projects with."}
                </p>
                {resume && (
                  <span className="d-flex mt-3">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      className="cta-btn cta-btn--resume"
                      href={resume}
                    >
                      Resume
                    </a>
                  </span>
                )}
              </div>
            </Fade>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default About;
